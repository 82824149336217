<template>
  <section class="content-account">
    <div class="content-account-itens">
      <div class="content-account-itens-banner" :style="{ backgroundImage: `url(${require('@/assets/img/bg-account.png')})` }">
        <div class="content-account-itens-banner--title">
          <h1>A minha conta</h1>
        </div>
      </div>
      <div class="container">
        <div class="content-box">
          <div class="content-box-details">
            <div class="content-box-details--title">
              <h2>As suas oportunidades</h2>
            </div>
            <div class="content-box-details-header">
              <div class="content-element">
                <p>submetidas 
					<span v-if="Object.keys(total).length">{{total.total}}</span>
					<span v-else>0</span>
				</p>
              </div>
              <div class="content-element">
                <p>aprovadas 
					<span v-if="Object.keys(total).length">{{ total.total_approved ? total.total_approved : 0 }}</span>
					<span v-else>0</span>
				</p>
              </div>
              <div class="content-element">
                <p>valor ganho 
					<span>
						<template>
							{{ valueEarned|money }}
						</template>
					</span>
				</p>
              </div>
            </div>
            <div class="content-box-details-table">
              <table>
                <tr class="hidden-mobile">
				          <th >Nome da empresa</th>
                  <th >Data submissão</th>
                  <th >Estado</th>
                </tr>
                <tr class="mobile-only">
				          <th >Nome da empresa</th>
                  <th >Estado</th>
                </tr>
				<template v-if="Object.keys(leads).length">
					<tr v-for="lead in leads.data" :key="lead.id">
						<td>{{lead.name_company}}</td>
						<!-- <td class="hidden-mobile"><router-link
								v-if="lead.campaign" 
								:to="{name: 'ranking', params: {slug: lead.campaign.slug}}">
								{{lead.campaign.name}}</router-link>
							<span v-else>- Empresa não encontrada -</span></td> -->
						<td class="hidden-mobile">{{lead.created_at|moment('DD/MM/YYYY')}}</td>
						<td class="aproved" :style="{color: lead.status.html_class}" v-if="lead.status.slug == 'waiting-review'">Oportunidade Submetida</td>
            <td class="aproved" :style="{color: lead.status.html_class}" v-else-if="lead.status.slug == 'review-progress'">Oportunidade em Análise</td>
            <td class="aproved" :style="{color: lead.status.html_class}" v-else-if="lead.status.slug == 'approved'">Oportunidade Concretizada</td>
            <td class="aproved" :style="{color: lead.status.html_class}" v-else-if="lead.status.slug == 'expired'">Oportunidade Expirada</td>
            <td class="aproved" :style="{color: lead.status.html_class}" v-else-if="lead.status.slug == 'cancelled'">Oportunidade não Concretizada</td>
					</tr>
				</template>
              </table>
			  	<!--  -->								
			  	<div v-if="Object.keys(leads).length && leads.last_page > 1" class="col-sm-12 mt-5 d-flex justify-content-center">
					<nav aria-label="Page navigation example">
						<ul class="pagination">
							<li class="page-item">
								<a class="page-link"
									:class="{
										isDisabled: (!(leads.current_page > 1))
									}"
									@click="setPage(leads.current_page - 1)">Voltar</a>											  
							</li>

							<template v-for="page in leads.last_page">
								<li class="page-item" :key="page"
								:class="{active: (leads.current_page == page)}">
									<a class="page-link" @click="setPage(page)">{{page}}</a>
								</li>
							</template>										
							
							<li class="page-item">
								<a class="page-link"
									:class="{
										isDisabled: ((leads.current_page == leads.last_page))
									}" 
									@click="setPage(leads.current_page + 1)">Avançar</a>
							</li>
						</ul>
					</nav>
				</div>
				<!--  -->
            </div>
            <!-- <button class="btn--black">
              <p>ver mais</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
            </button> -->
          </div>
          <div class="content-box-profile">
            <div class="content-box-profile--title">
              <h2>O seu perfil</h2>
            </div>
            <div class="content-box-profile-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="content-input">
                    <input type="text" name="name" :value="user.name" placeholder="Nome" readonly/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="content-input">
                    <input type="text" name="apelido" :value="user.nickname" placeholder="Apelido" readonly/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="content-input">
                    <input type="date" name="birthday" :value="user.birthday" placeholder="Data de nascimento" readonly/>
                  </div>
                </div>
              </div>
              <form ref="formUpdate" @submit.prevent="onUpdate" class="row">
                <div class="col-lg-8">
                  <div class="content-input">
                    <input type="email" name="email" :value="user.email" placeholder="Email"/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <button type="submit" class="btn--black">
                    <p>Alterar email</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="content-box-change-password">
            <div class="content-box-change-password--title">
              <h2>Alterar password</h2>
            </div>
            <form ref="formPassword" @submit.prevent="onPassword" class="content-box-change-password-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="content-input">
                    <input type="password" name="password" value="" placeholder="Nova password" required/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="content-input">
                    <input type="password" name="password_confirmation" value="" placeholder="Confirmar nova password" required/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button type="submit" class="btn--black">
                    <p>Alterar password</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
                  </button>
                </div>
                <div class="col-12">
                  <a class="btn--logoff" @click="onLogoff()">
                    Terminar sessão
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
	data(){
        return{
            leads : {}, 
			total: {},
			page: 1,  
			campaigns: [],         
			valueEarned: 0,
        }
    },  
	computed: {
		...mapGetters('User', ['user']),
	},
	methods:{		
        ...mapActions('App', ['loading']),  
		...mapActions('User', ['getUser','setUser', 'logoff']) ,
		async onPassword(){
			let formData = new FormData(this.$refs.formPassword);
			await this.save(formData)
			this.$refs.formPassword.reset()
		},  
        async onUpdate(el){            
            let formData = new FormData(this.$refs.formUpdate);
			await this.save(formData)
        },
		async save(formData){
			this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/update',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);				
				this.setUser(data.data);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });                
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
		},
		async setPage(page){
			this.page = page;

			await this.getList();
		},
        async getList(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/my-leads',
                params: {
                   page: this.page
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false); 				   	
               this.leads = data.leads;      
			   this.total = data.total;         
            })          
        },   
		async listCampaigns() {
			await axios({
				method: 'GET',
				url: '/api/v1/campaign/list-all',
				params: {
					is_active: 1
				}
			}).then(response => response.data.data)
			.then((data) => {
				this.campaigns = data;
			})
		},
		onLogoff(){
			this.logoff();
			this.$router.push({ name: 'login' });
      	},
		sumEarnedValue() {
			if (this.user.balance_users) {
        // delta-gamification sempre retorna apenas um registo de saldo por colaborador
				this.valueEarned = this.user.balance_users[0]?.balance ?? 0.00;
			}
		}
    },
	async mounted(){        		
    await this.getList();
		await this.listCampaigns();-
		await this.getUser();
		this.sumEarnedValue();
    }
}
</script>

<style lang="scss">
@import './index.scss';
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.hidden-mobile {
	@media (max-width: 991px){
		display: none;
	}
}
.mobile-only{
  @media (min-width: 992px){
    display: none;
  }
}
</style>
